import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';



import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from './components';


const TeacherDay2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const [config, setConfig] = useState({ base_image_archive_url: '' });
useEffect(() => {
  fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
    .then(response => response.json())
    .then(data => setConfig(data))
    .catch(error => console.error('Error fetching config:', error));
}, []);

const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/teacher_day/1.webp`;
const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/teacher_day/2.webp`;
const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/teacher_day/3.webp`;
const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/teacher_day/4.webp`;
const p5 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/teacher_day/5.webp`;
const p6 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/teacher_day/6.webp`;
const p7 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/teacher_day/7.webp`;
const p8 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/teacher_day/8.webp`;
const p9 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/teacher_day/9.webp`;
const p10 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/teacher_day/10.webp`;
const p11 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/teacher_day/11.webp`;
const p12 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/teacher_day/12.webp`;
const p13 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/teacher_day/13.webp`;

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        },
        {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
        },
        {
            src: p7,
            source: p7,
            rows: 1,
            cols: 1,
        },
        {
            src: p8,
            source: p8,
            rows: 1,
            cols: 1,
        },
        {
            src: p9,
            source: p9,
            rows: 1,
            cols: 1,
        },
        {
            src: p10,
            source: p10,
            rows: 1,
            cols: 1,
        },
        {
            src: p11,
            source: p11,
            rows: 1,
            cols: 1,
        },
        {
            src: p12,
            source: p12,
            rows: 2,
            cols: 1,
        },
        {
            src: p13,
            source: p13,
            rows: 1,
            cols: 1,
        },  
         
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                TEACHER’S DAY CELEBRATION
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                   Date: 5 SEPTEMBER 2023
                                </Typography>

                                <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                                ><br />
                                “Teachers plant the seeds of knowledge that grow forever”
                                <br />
                                </Typography>
                                <Typography variant={'subtitle1'} align={'justify'}>
                                Teacher’s Day was celebrated in National Public School on 5 September 2023 with great enthusiasm and zeal. The students performed for the teachers to express their respect and gratitude for them. The power packed performances in the form of dance and speech highlighted their love for the teachers. The colourful and rhythmic dances added to the spirit of the occasion. The students expressed their heartfelt gratitude and admiration for their teachers through their touching speeches. They praised their unwavering commitment to their educators. To add to the fun and camaraderie the students organised a few games for the teachers. Teachers and students came together to participate fostering a sense of togetherness and unity. The formal address by our respected Principal acknowledged the pivotal role played in shaping the future of students. It was a moment of reflection and inspiration for all. Each teacher and support staff member received tokens of appreciation for their sincere efforts. The teacher’s day was a heart-warming success, emphasising on the importance of the educators in shaping young minds. It was a day with laughter and appreciation. We look forward to continuing this tradition of honouring our educators in the years to come.
                                  
                                </Typography>
                            </Box>
                           
                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 300 : 200}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                            <Sidebar2023/>
                        </Box> 

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default TeacherDay2023;